var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section section--fullScreen section--flexCenter" },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading",
              },
            ],
          },
          [_c("VLoadSpinner", { attrs: { "on-page": "cart-payment" } })],
          1
        ),
        _vm.paymentError
          ? _c("p", [_vm._v(_vm._s(_vm.paymentError))])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }